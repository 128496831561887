const shop = () =>
    import ('@/views/shop');
const myorder = () =>
    import ('@/views/shop/myorder');
export const shopRoutes = [{
    name: 'shop',
    path: '/shop',
    meta: {
        title: '兑换商城',
        keepAlive: true,
        isUseCache: true,
    },
    component: shop
}, {
    name: 'myorder',
    path: '/shop/myorder',
    meta: {
        title: '我的订单'
    },
    component: myorder
}]