import { config } from "@/config";
import axios from 'axios';
import { Toast } from 'vant';
import signs from '@/utils/sign'
import { ua } from '@/utils/ua'
import { bridge } from '@/utils/bridge'
import { getToken } from '@/api/token'
import { useRouter } from 'vue-router'

// 根据环境不同引入不同api地址
const { title, baseUrl, baseApi, desc, phpBaseApi } = config
const httpService = axios.create({
    baseURL: baseApi, // url = base api url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})
httpService.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (config.url != '/api/tenant_convert/token') {
            if (token) {
                config.headers.YToken = token;
            }
        }

        return config
    },
    err => {
        return Promise.reject(err)
    })
httpService.interceptors.response.use(
    response => {
        if (response.data.code === 0) {
            return Promise.resolve(response.data);
        } else if (response.data.code === -1) { //处理失败
            Toast(response.data.msg);
        } else if (response.data.code === -2) { //token失效
            //重新获取token
            Toast(response.data.msg);
            if (ua.isAndroidApp || ua.isIosApp) {
                let router = useRouter();
                getToken({ tenantId: rs.tenantId, tenantUserToken: rs.token }).then(res => {
                    if (res.code === 0) {
                        localStorage.setItem('token', res.data.accessToken);
                        localStorage.setItem('tenantId', res.data.tenantId);
                        localStorage.setItem('tenantUserId', res.data.tenantUserId);
                        router.go(0)
                    }
                })
            } else {
                getToken({ tenantId: 100000, tenantUserToken: 'bearereyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC91c2VyLXNlcnZpY2Uuc3QzLnRlc3QubGFueGlua2EuY29tXC8yLjBcL3VzZXJzXC9sb2dpbiIsImlhdCI6MTY0MDA3Mjc3MywiZXhwIjoxNjQxMzY4NzczLCJuYmYiOjE2NDAwNzI3NzMsImp0aSI6IkVrREJ0RE1SNDNjNlpjeHkiLCJzdWIiOjUxMTcwMzEwLCJwcnYiOiIzYTdiMDZlOTUwZDA4ZTIzMzI5MjI3YzdhNmE1MjM0MmFiYjRmMTliIiwiYnVzaW5lc3NfdHlwZSI6Nn0.A6JhqRY1NlZL5JBD-NcU1yksPEXZjO5uUPsP7kfqDIY' }).then(rs => {
                    if (rs.code === 0) {
                        localStorage.setItem('token', rs.data.accessToken);
                        localStorage.setItem('tenantId', rs.data.tenantId);
                        localStorage.setItem('tenantUserId', rs.data.tenantUserId);
                        router.go(0)
                    }
                })
            }
        } else if (response.data.code === -3) { //未登录
            Toast(response.data.msg);
        } else if (response.data.code === -4) { //无访问权限
            Toast(response.data.msg);
        } else if (response.data.code === -5) { //token不合法
            Toast(response.data.msg);
        } else if (response.data.code === -6) { //token解析异常
            Toast(response.data.msg);
        } else if (response.data.code === -20001) { //第三方用户token失效，跳回到登录页面
            Toast(response.data.msg);
            if (ua.isAndroidApp || ua.isIosApp) {
                setTimeout(() => {
                    bridge.handleMethods('logout');
                }, 1500)
            }
        } else if (response.data.code === -20002) { //token解析失败，关闭webview重新进入
            Toast(response.data.msg);
            if (ua.isAndroidApp || ua.isIosApp) {
                setTimeout(() => {
                    bridge.handleMethods('quitWebview');
                }, 1500)
            }
        }
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        return Promise.reject(error.response.status) // 返回接口返回的错误信息
    })
export const requestPost = (url, params = {}, type = 'post') => {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: type,
            data: params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export const requestGet = (url, params = '') => {
    url = params ? `${url}?${params}` : url
    return new Promise((resolve, reject) => {
        httpService({
            url: url
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export const requestPhpPost = (url, params = {}) => {
    let sign = signs(params);

    params = Object.assign(params, { sign: sign.sign }, { timestamp: sign.timestamp })
    return new Promise((resolve, reject) => {
        axios.post(phpBaseApi + url, params)
            .then(function(response) {
                if (response.data.code === 0) {
                    resolve(response.data);
                } else {
                    console.log(phpBaseApi)

                }
            })
            .catch(function(error) {
                Toast(error.response);
            });
    })
}