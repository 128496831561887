import { ua } from './ua';
class Bridge {
    constructor() {}
    handleMethods(name, params, callback) {
        try {
            // 解决如果第二个参数是 callback 的情况，变量交换
            if (typeof params === 'function' && !callback) {
                callback = params;
                params = undefined;
            }
            if (ua.isAndroidApp || ua.isIosApp || ua.isSRZPApp) {
                if (!params) {
                    params = {}
                }
                if (callback) {
                    const functionName = `${name}${new Date().getTime()}`;

                    // 注册回调
                    Reflect.defineProperty(window, functionName, {
                        enumerable: false,
                        value: function(res) {
                            callback && callback(res)
                            Reflect.deleteProperty(window, functionName);
                        },
                    });

                    params.callback = functionName;
                }

                if (ua.isAndroidApp) {
                    window.control[name](JSON.stringify(params));
                } else if (ua.isIosApp) {
                    try {
                        window.webkit.messageHandlers[name].postMessage(JSON.stringify(params));
                    } catch (error) {
                        console.log('yjrh5----error------messageHandlers', error, error.name, error.message);
                    }
                }
            } else {
                console.log('此功能需要访问 APP 才能使用');
            }
        } catch (e) {
            console.log(`调用${name}方法失败,参数${JSON.stringify(params)}`);
            this.awakeAppUpdate();
        }
    }
    registerWindowEvent(name, callback, autoDestoryed = true) {
        try {
            Reflect.defineProperty(window, name, {
                enumerable: false,
                value: function(value) {
                    callback(value);
                    autoDestoryed && Reflect.deleteProperty(window, name);
                },
            });
        } catch (e) {
            console.log(e);
        }
    }
}
export const bridge = new Bridge();