const isUseCache = {
    namespaced: true,
    state: {
        useCache: true
    },
    mutations: {
        SET_IS_USE_CACHE(state, data) {
            state.useCache = data;
        }
    }
}

export default isUseCache;