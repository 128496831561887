const welfarecenter = () =>
    import ('@/views/welfarecenter');
const entrypackage = () =>
    import ('@/views/welfarecenter/entrypackage');
const drawpackage = () =>
    import ('@/views/welfarecenter/entrypackage/drawpackage');
const packagerules = () =>
    import ('@/views/welfarecenter/entrypackage/rules');

export const welfareRoutes = [{
    name: 'welfarecenter',
    path: '/welfarecenter/:id?',
    meta: {
        title: '福利中心'
    },
    component: welfarecenter
}, {
    name: 'entrypackage',
    path: '/welfarecenter/entrypackage',
    meta: {
        title: '入职礼包'
    },
    component: entrypackage
}, {
    name: 'drawpackage',
    path: '/welfarecenter/drawpackage',
    meta: {
        title: '入职礼包'
    },
    component: drawpackage
}, {
    name: 'packagerules',
    path: '/welfarecenter/packagerules',
    meta: {
        title: '使用规则'
    },
    component: packagerules
}]