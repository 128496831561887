import { createApp } from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible'
import router from './router'
import Vant from 'vant'
import Vconsole from 'vconsole';
import { Toast } from 'vant'
import '@/utils/rem.js'
import 'vant/lib/index.css' // 全局引入样式
import { bridge } from '@/utils/bridge'
import '@/styles/scss/index.scss'
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'
import store from './store'
const app = createApp(App);
app.config.globalProperties.$bridge = bridge;
app.use(Toast);
app.use(Vant).use(router).use(store).mount('#app');
process.env.VUE_APP_ENV == 'development' || process.env.VUE_APP_ENV == 'test' ? new Vconsole() : ''