import { createRouter, createWebHistory } from 'vue-router'
import { shopRoutes } from '@/router/modules/shop'
import { jdRoutes } from '@/router/modules/jdshop'
import { exchangeshopRoutes } from '@/router/modules/exchangeshop'
import { coinRoutes } from '@/router/modules/coin'
import { welfareRoutes } from '@/router/modules/welfarecenter'
import { addressRouter } from '@/router/modules/address'
import { ua } from '@/utils/ua'
import { bridge } from '@/utils/bridge'
import { getToken } from '@/api/token'
const routes = [
    ...shopRoutes,
    ...jdRoutes,
    ...exchangeshopRoutes,
    ...coinRoutes,
    ...welfareRoutes,
    ...addressRouter
];

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.afterEach((to, from) => {
    let pointerArr = [{ pointId: 410018, path: '/coin/rules' }, { pointId: 410019, path: '/exchangeshop' }, { pointId: 410020, path: '/jdshop' }, { pointId: 410021, path: '/shop/myorder' }]
    let pointerIndex = pointerArr.findIndex(item => item.path === to.path);
    if (pointerIndex > -1) {
        if (ua.isAndroidApp || ua.isIosApp) {
            bridge.handleMethods('newburyPoint', {
                pointId: pointerArr[pointerIndex].pointId,
                parentPointId: 0,
                accessTime: new Date().getTime()
            });
        }
    }
})

router.beforeEach((to, from, next) => {
    let userInfoArr = [{ path: '/shop' }, { path: '/coin' }, { path: '/welfarecenter' }, { path: '/welfarecenter/1' }];
    let userInfoIndex = userInfoArr.findIndex(item => item.path === to.path);
    if (userInfoIndex > -1) {
        if (ua.isAndroidApp || ua.isIosApp) {
            bridge.handleMethods('getUserInfo', rs => {
                console.log('sr', rs);
                localStorage.setItem('userInfo', JSON.stringify(rs));
                getToken({ tenantId: rs.tenantId, tenantUserToken: rs.token }).then(res => {
                    console.log('yjr', res);
                    if (res.code === 0) {
                        localStorage.setItem('token', res.data.accessToken);
                        localStorage.setItem('tenantId', res.data.tenantId);
                        localStorage.setItem('tenantUserId', res.data.tenantUserId);
                        next();
                    }
                })
            });
        } else {
            getToken({ tenantId: 2, tenantUserToken: 'bearereyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC91c2VyLXNlcnZpY2Uuc3QzLnRlc3QubGFueGlua2EuY29tXC8yLjBcL3VzZXJzXC9sb2dpbiIsImlhdCI6MTY0MDA3Mjc3MywiZXhwIjoxNjQxMzY4NzczLCJuYmYiOjE2NDAwNzI3NzMsImp0aSI6IkVrREJ0RE1SNDNjNlpjeHkiLCJzdWIiOjUxMTcwMzEwLCJwcnYiOiIzYTdiMDZlOTUwZDA4ZTIzMzI5MjI3YzdhNmE1MjM0MmFiYjRmMTliIiwiYnVzaW5lc3NfdHlwZSI6Nn0.A6JhqRY1NlZL5JBD-NcU1yksPEXZjO5uUPsP7kfqDIY' }).then(rs => {
                if (rs.code === 0) {

                    // localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJodHRwczovL2xvY2FsLWg1LWFwaS5ybmcuY29tIiwibmJmIjoxNjQwNzQ5MjY0LCJkYXRhIjp7Im5hbWUiOiLph5HniYzorrLluIgiLCJtb2JpbGUiOiIxMzMzODYyNjY2NiIsInRlbmFudElkIjoxMDAwMDAsInRlbmFudFVzZXJJZCI6NTExNzI0NjQsInVzZXJJZCI6IjY3MzI0NTQyNTI1MTEyMzIwMCJ9LCJzY29wZSI6InJvbGVfYWNjZXNzIiwiaXNzIjoiaHR0cHM6Ly9sb2NhbC1oNS1hcGkucm5nLmNvbSIsImV4cCI6MTY0MzM0MTI2NCwiaWF0IjoxNjQwNzQ5MjY0fQ.qeVtHjTbTH2FOnOrrF9owZf5SX6CRcsUmt0EO9ONAxY');
                    // localStorage.setItem('tenantId', 100000);
                    // localStorage.setItem('tenantUserId', 51172464);
                    localStorage.setItem('tenantId', rs.data.tenantId);
                    localStorage.setItem('tenantUserId', rs.data.tenantUserId);
                    localStorage.setItem('token', rs.data.accessToken);
                    next();
                }
            })
        }

    } else {
        next();
    }
})


export default router