import store from '@/store'
const jdshop = () =>
    import ('@/views/jdshop');
const classfiy = () =>
    import ('@/views/jdshop/classfiy');
const jdsearch = () =>
    import ('@/views/jdshop/jdsearch');
const jdtwosearch = () =>
    import ('@/views/jdshop/jdtwosearch')
const jdGoodsDetail = () =>
    import ('@/views/jdshop/goodsdetail')
const sureOrder = () =>
    import ('@/views/jdshop/sureOrder')
const checkstand = () =>
    import ('@/views/jdshop/checkstand')
const paysuccess = () =>
    import ('@/views/jdshop/paysuccess')
const orderdetail = () =>
    import ('@/views/jdshop/orderdetail')
const orderTracking = () =>
    import ('@/views/jdshop/orderTracking')
export const jdRoutes = [{
        name: 'jdshop',
        path: '/jdshop',
        meta: {
            title: '京东商城',
            keepAlive: true,
            isUseCache: true
        },
        component: jdshop
    }, {
        name: 'classfiy',
        path: '/jdshop/classfiy',
        meta: {
            title: '精选兑换'
        },
        component: classfiy
    }, {
        name: 'jdsearch',
        path: '/jdshop/jdsearch',
        meta: {
            title: '京东商城一级搜索',
            keepAlive: true,
            isUseCache: true
        },
        component: jdsearch
    }, {
        name: 'jdtwosearch',
        path: '/jdshop/jdtwosearch',
        meta: {
            title: '京东商城二级搜索',
            keepAlive: true,
            isUseCache: true
        },
        component: jdtwosearch
    }, {
        name: 'jdGoodsDetail',
        path: '/jdshop/goodsDetail',
        meta: {
            title: ''
        },
        component: jdGoodsDetail
    }, {
        name: 'sureOrder',
        path: '/jdshop/sureOrder',
        meta: {
            title: '确认订单'
        },
        component: sureOrder
    },
    {
        name: 'checkstand',
        path: '/jdshop/checkstand',
        meta: {
            title: '收银台'
        },
        component: checkstand
    },
    {
        name: 'paysuccess',
        path: '/jdshop/paysuccess',
        meta: {
            title: '支付成功'
        },
        component: paysuccess
    },
    {
        name: 'orderdetail',
        path: '/jdshop/orderdetail',
        meta: {
            title: '订单详情'
        },
        component: orderdetail
    },
    {
        name: 'orderTracking',
        path: '/jdshop/orderTracking',
        meta: {
            title: '订单跟踪'
        },
        component: orderTracking
    }
]