import { createStore } from "vuex";
import getters from "./getter";
const files = require.context("./modules", false, /\.js$/);
const modules = {};
files.keys().forEach(key => {
    modules[key.replace(/(\.\/|\.js)/g, "")] = files(key).default;
});
export default createStore({
    modules,
    getters
});