const coin = () =>
    import ('@/views/coin');
const rules = () =>
    import ('@/views/coin/rules');
export const coinRoutes = [{
    name: 'coin',
    path: '/coin',
    meta: {
        title: '工钱币'
    },
    component: coin
}, {
    name: 'coinrules',
    path: '/coin/rules',
    meta: {
        title: '工钱币规则'
    },
    component: rules
}]