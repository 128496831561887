const addressList = () =>
    import ('@/views/address');
const editAddress = () =>
    import ('@/views/address/editAddress');
export const addressRouter = [{
    name: 'addressList',
    path: '/address/addressList',
    meta: {
        title: '收货地址'
    },
    component: addressList
}, {
    name: 'editAddress',
    path: '/address/editAddress',
    meta: {
        title: '新建收货地址'
    },
    component: editAddress
}]