module.exports = {
    title: '一家仁h5测试',
    baseUrl: 'https://test.xxx.com', //test 测试项目地址
    baseApi: 'http://tenant-m.yjr88.com', //test 测试api请求地址
    APPID: 'xxx',
    APPSECRET: 'xxx',
    desc: '本地测试',
    ossUrl: 'https://yjr-prod-op.oss-cn-shanghai.aliyuncs.com/yjr-memberh5',
    shurenH5URL: 'https://business-h5.st3.shurenzhipin.com',
    phpBaseApi: 'https://d.yjr88.com'
}