const exchangeshop = () =>
    import ('@/views/exchangeshop');
const searchshop = () =>
    import ('@/views/exchangeshop/searchshop');
const exchangesearch = () =>
    import ('@/views/exchangeshop/exchangesearch');
const goodsdetail = () =>
    import ('@/views/exchangeshop/goodsdetail');
export const exchangeshopRoutes = [{
    name: 'exchangeshop',
    path: '/exchangeshop',
    meta: {
        title: '精选兑换',
        keepAlive: true,
        isUseCache: true
    },
    component: exchangeshop
}, {
    name: 'searchshop',
    path: '/exchangeshop/searchshop',
    meta: {
        title: '精选兑换搜索'
    },
    component: searchshop
}, {
    name: 'exchangesearch',
    path: '/exchangeshop/exchangesearch',
    meta: {
        title: '精选兑换搜索列表',
        keepAlive: true,
        isUseCache: true
    },
    component: exchangesearch
}, {
    name: 'goodsdetail',
    path: '/exchangeshop/goodsdetail',
    meta: {
        title: ''
    },
    component: goodsdetail
}]