import md5 from 'js-md5'
import qs from 'qs'
const sign = config => {
    const timestamp = +new Date()
    let newkey = Object.keys(config).sort();
    //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
    let newObj = {}; //创建一个新的对象，用于存放排好序的键值对
    for (let i = 0; i < newkey.length; i++) { //遍历newkey数组
        newObj[newkey[i]] = config[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
    }
    return { sign: md5(md5(qs.stringify(newObj)) + md5('a3454be17dce99ed7188b7ac524cd8cd')), timestamp }
}

export default sign