export const userAgent = {
    agent: navigator.userAgent
}
let ua = null;
export function createUa() {
    ua = {
        isAndroidApp: /android_kbgz_app/.test(userAgent.agent) || /android_bd_app/.test(userAgent.agent) || /android_ck_app/.test(userAgent.agent),

        isIosApp: /ios_kbgz_app/.test(userAgent.agent) || /com.xinrenlei.koubeigongzuobd/.test(userAgent.agent) || /ios_ck_app/.test(userAgent.agent),
        // isIosApp: /android_kbgz_app/.test(userAgent.agent)
        isSRZPApp: /android_kbgz_app/.test(userAgent.agent) || /ios_kbgz_app/.test(userAgent.agent) || /com.xinrenlei.koubeigongzuobd/.test(userAgent.agent),
        // ----  微信  --------
        isWechat: /MicroMessenger/i.test(userAgent.agent),

        // ------ 其他浏览器内核 ------
        isSafari: /Safari/.test(userAgent.agent),

        // ------ 小程序内核  阿里小程序开发平台------
        isMpWechat: /miniProgram/i.test(userAgent.agent) || /AlipayIDE/i.test(userAgent.agent),

        // ------ 支付宝内核 ------
        isAlipayClient: /Alipay/.test(userAgent.agent),

        // ------ 小程序 ------
        isMpProgram: /Alipay/.test(userAgent.agent) || /miniProgram/i.test(userAgent.agent) || /AlipayIDE/i.test(userAgent.agent),

        // ------ android ------
        _android: /Android|Adr/i.test(userAgent.agent),

        // ------ IOS ------
        _ios: /iP(ad|hone|od)/i.test(userAgent.agent),
    };
}

createUa(); // 8.0.1 发现某些机型会获取不到app修改后的userAgent 路由守卫中修改下

// 仿真设备
export const isMacIntel = !!navigator.platform.match(/Win|Mac/i);

const isAppVersionEqual = (version) => {
    let str = [];
    let regStr;
    const reg = /\/(\d.)(\d)+(.\d+)?\//g;

    if (ua.isAndroidApp) {
        str = userAgent.agent.split('android_kbgz_app');
        regStr = str[1].match(reg)[0].split('/')[1];
    } else if (ua.isIosApp) {
        str = userAgent.agent.split('iOS');
        regStr = str[1].match(reg)[0].split('/')[1];
    }

    if (!regStr) {
        return false;
    }
    return regStr >= version;
};

export { ua, isAppVersionEqual };